import React from 'react'
import tw from 'twin.macro'

export default function MobileMenuSvg({ variant }) {
  if (variant === 'open') {
    return (
      <svg
        focusable="false"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={tw`w-6 h-6 fill-current`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 8H4V6h16v2zm0 5H4v-2h16v2zm0 5H4v-2h16v2z"
        ></path>
      </svg>
    )
  }
  if (variant === 'close') {
    return (
      <svg
        focusable="false"
        css={tw`w-6 h-6 fill-current text-black`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13.415l4.95 4.95 1.414-1.415-4.95-4.95 4.95-4.95-1.415-1.413-4.95 4.95-4.949-4.95L5.636 7.05l4.95 4.95-4.95 4.95 1.414 1.414 4.95-4.95z"
        ></path>
      </svg>
    )
  }
  if (variant === 'back') {
    return (
      <svg
        focusable="false"
        className="svg-icon  hnf-svg-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        css={tw`text-black mt-4 mb-4`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.999 12l8 8 1.414-1.413L7.827 13H20v-2H7.827l5.587-5.587L12 4l-8.001 8z"
        ></path>
      </svg>
    )
  }
}
