import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'

const StyledLi = styled.li`
  &.btn {
    ${tw`ml-4`}
  }
  & {
    ${tw`cursor-default relative py-3 px-0`}
  }
  @media (max-width: 1280px) {
    ${tw`py-4 px-0`}
  }
  &:not(.btn) > a {
    ${tw`transition-transform`}
  }
  &:hover:not(.btn) > a {
    transform: scale(1.1);
  }
  &:not(.btn):hover ~ .subMenuWrapper {
    transform: perspective(600px) rotateX(0);
    ${tw`visible opacity-100`}
  }
  & ~ .subMenuWrapper ul > li > a .titleMenu {
    transition: all 500ms;
    ${tw`relative`}
  }
  & ~ .subMenuWrapper ul > li > a:hover .titleMenu:after,
  ~ .subMenuWrapper ul > li > [aria-current='page'] .titleMenu:after {
    content: '›';
    top: -5px;
    left: -10px;
    ${tw`absolute`}
  }
  & ~ .subMenuWrapper ul > li > a:hover .titleMenu,
  ~ .subMenuWrapper ul > li > [aria-current='page'] .titleMenu {
    transform: translate(5px, 0px);
  }
  // & ~ .subMenuWrapper ul > li > [aria-current='page'],
  // & ~ .subMenuWrapper ul > li > [aria-current='page'] ~ span {
  //   color: #999 !important;
  // }
  &:nth-of-type(1):hover ~ .subMenuWrapper > ul:nth-of-type(1) {
    ${tw`grid`}
  }
  &:nth-of-type(3):hover ~ .subMenuWrapper > ul:nth-of-type(2) {
    ${tw`grid`}
  }
  &:nth-of-type(4):hover ~ .subMenuWrapper > ul:nth-of-type(3) {
    ${tw`grid`}
  }
  &:nth-of-type(5):hover ~ .subMenuWrapper > ul:nth-of-type(4) {
    ${tw`grid`}
  }
  &:nth-of-type(6):hover ~ .subMenuWrapper > ul:nth-of-type(5) {
    ${tw`grid`}
  }
`

const StyledLink = styled(Link)`
  ${({ darkmenu, showasbutton }) => [
    tw`block mx-2 lg:mx-4 xl:mx-6 px-0 py-2 uppercase font-bold text-white`,
    css`
      text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
    `,
    darkmenu && tw`text-navy`,
    showasbutton &&
      tw`block px-4 lg:px-6 py-2 uppercase font-bold border-white border-2 text-white rounded-full`,
    showasbutton && darkmenu && tw`border-navy text-navy`,
  ]}
`

const StyledDiv = styled.div`
  ${({ darkmenu, showasbutton }) => [
    tw`block px-4 lg:px-6 py-2 uppercase font-bold text-white`,
    css`
      text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
    `,
    darkmenu && tw`block px-4 lg:px-6 py-2 uppercase font-bold text-navy`,
    showasbutton &&
      tw`block px-4 lg:px-6 py-2 uppercase font-bold bg-white text-navy rounded-full`,
    showasbutton &&
      darkmenu &&
      tw`block px-4 lg:px-6 py-2 uppercase font-bold bg-stm text-white rounded-full`,
  ]}
`

export default function NavItem({
  nolink,
  href,
  slug,
  children,
  showAsButton,
  darkMenu,
  mobileMenuState,
  setMobileSubMenu,
  setMobileSubMenuWrapperShow,
  subMenuItems,
}) {
  const handleClick = (slug) => {
    setMobileSubMenuWrapperShow(true)
    setMobileSubMenu(slug)
  }

  // MOBILE MENU
  if (mobileMenuState) {
    return (
      <div
        className="mobile MenuItem"
        css={tw`flex items-center justify-between mt-4`}
      >
        {!nolink && (
          <Link
            to={href}
            css={tw`text-2xl font-bold text-navy leading-relaxed`}
          >
            {children}
          </Link>
        )}
        {subMenuItems.length !== 0 && (
          <button
            onClick={() => handleClick(slug)}
            className={slug}
            css={[
              tw`font-bold leading-relaxed text-left float-right`,
              css`
                font-size: 1.5rem;
              `,
            ]}
          >
            {!nolink ? '+' : children}
          </button>
        )}
      </div>
    )
  }

  // DESKTOP MENU
  if (!mobileMenuState) {
    return (
      <StyledLi
        darkmenu={darkMenu ? 'true' : undefined}
        showasbutton={showAsButton ? 'true' : undefined}
        className={showAsButton && ''}
      >
        {!nolink && (
          <StyledLink
            darkmenu={darkMenu ? 'true' : undefined}
            showasbutton={showAsButton ? 'true' : undefined}
            partiallyActive={true}
            activeClassName={'mainActive'}
            // activeStyle={{
            // backgroundColor: 'rgba(0,0,0,0.15)',
            //  borderBottom: '3px solid #000',
            // }}
            to={href}
          >
            {children}
          </StyledLink>
        )}
        {nolink && (
          <StyledDiv
            darkmenu={darkMenu ? 'true' : undefined}
            showasbutton={showAsButton ? 'true' : undefined}
          >
            {children}
          </StyledDiv>
        )}
      </StyledLi>
    )
  }
}
