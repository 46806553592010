import React from 'react'
import tw, { css } from 'twin.macro'
import MobileMenuSvg from '../Svgs/MobileMenu'

export default function Nav({
  children,
  darkMenu,
  mobileMenuState,
  setMobileMenuState,
  setMobileSubMenuWrapperShow,
}) {
  const mobileMenuClick = () => {
    setMobileMenuState(!mobileMenuState)
    setMobileSubMenuWrapperShow(false)
  }
  return (
    <>
      <nav
        css={tw`hidden md:flex justify-between text-white items-center relative`}
      >
        <ul css={tw`flex`}>{children}</ul>
      </nav>
      <nav css={tw`md:hidden z-50`} className="toggleNav">
        <button
          name="navigation"
          aria-label="Vis hovedmenu"
          css={[
            tw`relative z-50 text-xs focus:outline-none leading-snug text-white hover:bg-navy hover:bg-opacity-30 rounded-full py-2 px-2`,
            darkMenu && tw`text-black bg-white bg-opacity-70`,
          ]}
          onClick={() => mobileMenuClick()}
        >
          {mobileMenuState ? (
            <MobileMenuSvg variant="close" />
          ) : (
            <MobileMenuSvg variant="open" />
          )}
        </button>
        <div
          className={`mobile-menu-wrapper ${mobileMenuState && 'show'}`}
          css={[
            css`
              & {
                transform: translateX(-100%);
                transition: transform 0.2s;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
                ${tw`px-8 py-8`}
              }
              &.show {
                transform: translateX(0);
              }
            `,
            tw`bg-white fixed top-0 left-0 h-screen w-screen text-navy`,
          ]}
        >
          <ul css={tw`flex flex-col mt-16`}>{children}</ul>
        </div>
      </nav>
    </>
  )
}
