import React from 'react'
import { css } from 'twin.macro'

export default function Logo({ variant }) {
  if (variant === 'dark') {
    return (
      <svg
        css={css`
          @media (min-width: 768px) {
            height: auto;
            min-width: 100px;
          }
          @media (min-width: 1260px) {
            min-width: 120px;
          }
          .cls-1 {
            fill: #464340;
          }
        `}
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 388.88 388.63"
      >
        <g>
          <path
            className="cls-1"
            d="m158.39,272.27c-.38,0-.69-.1-.92-.31-.23-.2-.39-.45-.49-.74l-7.56-23.08c-.02-.12-.04-.23-.04-.32,0-.19.07-.37.22-.52.14-.16.32-.23.54-.23h3.78c.36,0,.64.1.85.31s.33.39.38.56l5.62,17.6,5.58-17.6c.07-.17.2-.35.4-.56.19-.2.47-.31.83-.31h3.78c.22,0,.4.08.54.23.14.16.22.33.22.52,0,.1-.01.2-.04.32l-7.56,23.08c-.07.29-.22.53-.45.74s-.55.31-.95.31h-4.72Z"
          />
          <path
            className="cls-1"
            d="m183.09,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h4.07c.26,0,.48.08.65.25.17.17.25.38.25.65v23.4c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-4.07Z"
          />
          <path
            className="cls-1"
            d="m200.76,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h3.28c.36,0,.62.08.79.25.17.17.28.3.32.4l9.61,15.01v-14.76c0-.26.08-.48.25-.65.17-.17.37-.25.61-.25h3.67c.26,0,.48.08.65.25.17.17.25.38.25.65v23.4c0,.24-.08.45-.25.63-.17.18-.38.27-.65.27h-3.31c-.36,0-.62-.09-.77-.27-.16-.18-.27-.31-.34-.38l-9.58-14.54v14.29c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-3.64Z"
          />
          <path
            className="cls-1"
            d="m232.91,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h9.04c2.4,0,4.4.38,6.01,1.13,1.61.76,2.84,1.86,3.69,3.31.85,1.45,1.3,3.25,1.35,5.38.02,1.06.04,1.98.04,2.77s-.01,1.7-.04,2.74c-.07,2.23-.52,4.08-1.33,5.54-.82,1.46-2.02,2.55-3.6,3.26s-3.56,1.06-5.94,1.06h-9.22Zm4.82-4.64h4.21c1.2,0,2.18-.18,2.95-.54.77-.36,1.34-.93,1.71-1.71s.57-1.79.59-3.04c.02-.7.04-1.32.05-1.87.01-.55.01-1.1,0-1.66-.01-.55-.03-1.16-.05-1.84-.05-1.8-.51-3.13-1.39-3.98s-2.23-1.28-4.05-1.28h-4.03v15.91Z"
          />
          <path
            className="cls-1"
            d="m274.31,272.63c-2.11,0-3.96-.35-5.54-1.06-1.58-.71-2.81-1.8-3.67-3.28-.86-1.48-1.3-3.35-1.3-5.63v-14.69c0-.26.08-.48.25-.65s.38-.25.65-.25h3.92c.26,0,.48.08.65.25.17.17.25.38.25.65v14.65c0,1.78.42,3.1,1.26,3.96.84.86,2.02,1.3,3.53,1.3s2.65-.43,3.49-1.3c.84-.86,1.26-2.18,1.26-3.96v-14.65c0-.26.09-.48.27-.65.18-.17.39-.25.63-.25h3.96c.24,0,.44.08.61.25.17.17.25.38.25.65v14.69c0,2.28-.43,4.16-1.3,5.63-.86,1.48-2.08,2.57-3.64,3.28-1.56.71-3.41,1.06-5.54,1.06Z"
          />
          <path
            className="cls-1"
            d="m297.28,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h16.31c.26,0,.48.08.65.25.17.17.25.38.25.65v2.88c0,.24-.08.44-.25.61-.17.17-.38.25-.65.25h-11.66v5.65h10.87c.26,0,.48.08.65.25.17.17.25.38.25.65v2.66c0,.24-.08.44-.25.61-.17.17-.38.25-.65.25h-10.87v5.83h11.95c.26,0,.48.08.65.25.17.17.25.38.25.65v2.84c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-16.6Z"
          />
          <path
            className="cls-1"
            d="m326.44,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h9.54c3,0,5.36.69,7.09,2.07,1.73,1.38,2.59,3.37,2.59,5.96,0,1.75-.42,3.22-1.26,4.39-.84,1.18-1.97,2.05-3.38,2.63l5.08,9c.07.14.11.28.11.4,0,.19-.07.37-.22.52-.14.16-.32.23-.54.23h-4c-.43,0-.76-.11-.97-.34-.22-.23-.37-.44-.47-.63l-4.39-8.24h-4.36v8.32c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-3.92Zm4.82-13.79h4.61c1.32,0,2.3-.3,2.93-.9s.95-1.44.95-2.52-.31-1.93-.94-2.56c-.62-.62-1.61-.94-2.95-.94h-4.61v6.91Z"
          />
        </g>
        <g>
          <path
            className="cls-1"
            d="m66.36,221.44c-5.53,0-10.28-.74-14.27-2.23-3.99-1.49-7.25-3.27-9.81-5.34-2.55-2.07-4.44-4.12-5.66-6.14-1.22-2.02-1.89-3.56-1.99-4.62-.11-1.17.26-2.05,1.12-2.63.85-.58,1.65-.88,2.39-.88h2.39c.64,0,1.2.11,1.67.32.48.21,1.09.8,1.83,1.75,1.38,1.81,2.97,3.53,4.78,5.18,1.81,1.65,4.12,2.95,6.94,3.91,2.81.96,6.35,1.44,10.6,1.44,6.38,0,11.64-1.22,15.78-3.67,4.15-2.44,6.22-6.06,6.22-10.84,0-2.97-.82-5.37-2.47-7.17-1.65-1.81-4.44-3.4-8.37-4.78-3.93-1.38-9.25-2.82-15.94-4.3-6.7-1.59-11.83-3.51-15.39-5.74-3.56-2.23-5.98-4.81-7.25-7.73-1.28-2.92-1.91-6.08-1.91-9.49,0-3.72,1.06-7.36,3.19-10.92,2.12-3.56,5.29-6.48,9.49-8.77,4.2-2.28,9.49-3.43,15.86-3.43,5.21,0,9.65.69,13.31,2.07,3.67,1.38,6.67,3.06,9.01,5.02,2.34,1.97,4.07,3.91,5.18,5.82,1.12,1.91,1.73,3.46,1.83,4.62.1,1.06-.21,1.91-.96,2.55-.74.64-1.59.96-2.55.96h-2.39c-.85,0-1.54-.18-2.07-.56-.53-.37-1.01-.88-1.44-1.51-1.06-1.7-2.34-3.29-3.83-4.78-1.49-1.49-3.48-2.68-5.98-3.59-2.5-.9-5.87-1.36-10.12-1.36-6.38,0-11.11,1.3-14.19,3.91-3.08,2.61-4.62,5.93-4.62,9.97,0,2.34.64,4.38,1.91,6.14s3.67,3.35,7.17,4.78c3.51,1.44,8.61,2.95,15.31,4.54,6.8,1.49,12.17,3.32,16.1,5.5,3.93,2.18,6.72,4.76,8.37,7.73,1.65,2.98,2.47,6.48,2.47,10.52,0,4.25-1.2,8.19-3.59,11.8-2.39,3.61-5.95,6.51-10.68,8.69-4.73,2.18-10.55,3.27-17.46,3.27Z"
          />
          <path
            className="cls-1"
            d="m233.48,219.85c-1.06,0-1.91-.32-2.55-.96s-.96-1.49-.96-2.55v-75.89c0-1.06.32-1.91.96-2.55.64-.64,1.49-.96,2.55-.96h2.71c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v5.58c2.66-3.4,5.82-6.03,9.49-7.89,3.67-1.86,8.1-2.79,13.31-2.79,11.9,0,20.41,5,25.51,14.99,2.44-4.57,6.03-8.21,10.76-10.92,4.73-2.71,10.18-4.07,16.34-4.07,5.63,0,10.71,1.3,15.23,3.91,4.52,2.61,8.08,6.46,10.68,11.56,2.6,5.1,3.91,11.48,3.91,19.13v46.4c0,1.06-.32,1.91-.96,2.55s-1.49.96-2.55.96h-2.71c-1.06,0-1.91-.32-2.55-.96-.64-.64-.96-1.49-.96-2.55v-44.8c0-7.02-1.01-12.46-3.03-16.34-2.02-3.88-4.68-6.62-7.97-8.21-3.3-1.59-6.85-2.39-10.68-2.39-3.19,0-6.43.8-9.72,2.39-3.3,1.59-6.03,4.33-8.21,8.21-2.18,3.88-3.27,9.33-3.27,16.34v44.8c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-2.71c-1.06,0-1.91-.32-2.55-.96s-.96-1.49-.96-2.55v-44.8c0-7.02-1.06-12.46-3.19-16.34-2.13-3.88-4.84-6.62-8.13-8.21-3.3-1.59-6.75-2.39-10.36-2.39-3.19,0-6.43.8-9.72,2.39-3.3,1.59-6.03,4.33-8.21,8.21-2.18,3.88-3.27,9.27-3.27,16.18v44.96c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-2.71Z"
          />
        </g>
        <path
          className="cls-1"
          d="m149.46,136.95v-26.79c0-1.06.32-1.91.96-2.55.64-.64,1.49-.96,2.55-.96h2.71c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v26.79h19.77c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v2.23c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-19.77v46.4c0,5.63.93,10.04,2.79,13.23,1.86,3.19,5.18,4.78,9.96,4.78h8.61c1.06,0,1.91.32,2.55.96s.96,1.49.96,2.55v2.23c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-9.41c-5.53,0-9.89-1.17-13.07-3.51-3.19-2.34-5.42-5.53-6.7-9.57-1.28-4.04-1.91-8.56-1.91-13.55v-47.03"
        />
        <path
          className="cls-1"
          d="m379.63,246.79v132.59H9.25v-132.59H0v138.33c0,1.06.32,1.91.96,2.55.64.64,1.49.96,2.55.96h381.86c1.06,0,1.91-.32,2.55-.96.64-.64.96-1.49.96-2.55v-138.33h-9.25Z"
        />
        <path
          className="cls-1"
          d="m379.63,135.35V9.25H9.25v132.59H0V3.51C0,2.44.32,1.59.96.96c.64-.64,1.49-.96,2.55-.96h381.86c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v131.84h-9.25Z"
        />
      </svg>
    )
  }
  return (
    <svg
      css={css`
        @media (min-width: 768px) {
          height: auto;
          min-width: 100px;
        }
        @media (min-width: 1260px) {
          min-width: 120px;
        }
        .cls-1 {
          fill: #fff;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 388.88 388.63"
    >
      <g>
        <path
          className="cls-1"
          d="m158.39,272.27c-.38,0-.69-.1-.92-.31-.23-.2-.39-.45-.49-.74l-7.56-23.08c-.02-.12-.04-.23-.04-.32,0-.19.07-.37.22-.52.14-.16.32-.23.54-.23h3.78c.36,0,.64.1.85.31s.33.39.38.56l5.62,17.6,5.58-17.6c.07-.17.2-.35.4-.56.19-.2.47-.31.83-.31h3.78c.22,0,.4.08.54.23.14.16.22.33.22.52,0,.1-.01.2-.04.32l-7.56,23.08c-.07.29-.22.53-.45.74s-.55.31-.95.31h-4.72Z"
        />
        <path
          className="cls-1"
          d="m183.09,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h4.07c.26,0,.48.08.65.25.17.17.25.38.25.65v23.4c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-4.07Z"
        />
        <path
          className="cls-1"
          d="m200.76,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h3.28c.36,0,.62.08.79.25.17.17.28.3.32.4l9.61,15.01v-14.76c0-.26.08-.48.25-.65.17-.17.37-.25.61-.25h3.67c.26,0,.48.08.65.25.17.17.25.38.25.65v23.4c0,.24-.08.45-.25.63-.17.18-.38.27-.65.27h-3.31c-.36,0-.62-.09-.77-.27-.16-.18-.27-.31-.34-.38l-9.58-14.54v14.29c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-3.64Z"
        />
        <path
          className="cls-1"
          d="m232.91,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h9.04c2.4,0,4.4.38,6.01,1.13,1.61.76,2.84,1.86,3.69,3.31.85,1.45,1.3,3.25,1.35,5.38.02,1.06.04,1.98.04,2.77s-.01,1.7-.04,2.74c-.07,2.23-.52,4.08-1.33,5.54-.82,1.46-2.02,2.55-3.6,3.26s-3.56,1.06-5.94,1.06h-9.22Zm4.82-4.64h4.21c1.2,0,2.18-.18,2.95-.54.77-.36,1.34-.93,1.71-1.71s.57-1.79.59-3.04c.02-.7.04-1.32.05-1.87.01-.55.01-1.1,0-1.66-.01-.55-.03-1.16-.05-1.84-.05-1.8-.51-3.13-1.39-3.98s-2.23-1.28-4.05-1.28h-4.03v15.91Z"
        />
        <path
          className="cls-1"
          d="m274.31,272.63c-2.11,0-3.96-.35-5.54-1.06-1.58-.71-2.81-1.8-3.67-3.28-.86-1.48-1.3-3.35-1.3-5.63v-14.69c0-.26.08-.48.25-.65s.38-.25.65-.25h3.92c.26,0,.48.08.65.25.17.17.25.38.25.65v14.65c0,1.78.42,3.1,1.26,3.96.84.86,2.02,1.3,3.53,1.3s2.65-.43,3.49-1.3c.84-.86,1.26-2.18,1.26-3.96v-14.65c0-.26.09-.48.27-.65.18-.17.39-.25.63-.25h3.96c.24,0,.44.08.61.25.17.17.25.38.25.65v14.69c0,2.28-.43,4.16-1.3,5.63-.86,1.48-2.08,2.57-3.64,3.28-1.56.71-3.41,1.06-5.54,1.06Z"
        />
        <path
          className="cls-1"
          d="m297.28,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h16.31c.26,0,.48.08.65.25.17.17.25.38.25.65v2.88c0,.24-.08.44-.25.61-.17.17-.38.25-.65.25h-11.66v5.65h10.87c.26,0,.48.08.65.25.17.17.25.38.25.65v2.66c0,.24-.08.44-.25.61-.17.17-.38.25-.65.25h-10.87v5.83h11.95c.26,0,.48.08.65.25.17.17.25.38.25.65v2.84c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-16.6Z"
        />
        <path
          className="cls-1"
          d="m326.44,272.27c-.26,0-.48-.08-.65-.25-.17-.17-.25-.38-.25-.65v-23.4c0-.26.08-.48.25-.65.17-.17.38-.25.65-.25h9.54c3,0,5.36.69,7.09,2.07,1.73,1.38,2.59,3.37,2.59,5.96,0,1.75-.42,3.22-1.26,4.39-.84,1.18-1.97,2.05-3.38,2.63l5.08,9c.07.14.11.28.11.4,0,.19-.07.37-.22.52-.14.16-.32.23-.54.23h-4c-.43,0-.76-.11-.97-.34-.22-.23-.37-.44-.47-.63l-4.39-8.24h-4.36v8.32c0,.26-.08.48-.25.65-.17.17-.38.25-.65.25h-3.92Zm4.82-13.79h4.61c1.32,0,2.3-.3,2.93-.9s.95-1.44.95-2.52-.31-1.93-.94-2.56c-.62-.62-1.61-.94-2.95-.94h-4.61v6.91Z"
        />
      </g>
      <g>
        <path
          className="cls-1"
          d="m66.36,221.44c-5.53,0-10.28-.74-14.27-2.23-3.99-1.49-7.25-3.27-9.81-5.34-2.55-2.07-4.44-4.12-5.66-6.14-1.22-2.02-1.89-3.56-1.99-4.62-.11-1.17.26-2.05,1.12-2.63.85-.58,1.65-.88,2.39-.88h2.39c.64,0,1.2.11,1.67.32.48.21,1.09.8,1.83,1.75,1.38,1.81,2.97,3.53,4.78,5.18,1.81,1.65,4.12,2.95,6.94,3.91,2.81.96,6.35,1.44,10.6,1.44,6.38,0,11.64-1.22,15.78-3.67,4.15-2.44,6.22-6.06,6.22-10.84,0-2.97-.82-5.37-2.47-7.17-1.65-1.81-4.44-3.4-8.37-4.78-3.93-1.38-9.25-2.82-15.94-4.3-6.7-1.59-11.83-3.51-15.39-5.74-3.56-2.23-5.98-4.81-7.25-7.73-1.28-2.92-1.91-6.08-1.91-9.49,0-3.72,1.06-7.36,3.19-10.92,2.12-3.56,5.29-6.48,9.49-8.77,4.2-2.28,9.49-3.43,15.86-3.43,5.21,0,9.65.69,13.31,2.07,3.67,1.38,6.67,3.06,9.01,5.02,2.34,1.97,4.07,3.91,5.18,5.82,1.12,1.91,1.73,3.46,1.83,4.62.1,1.06-.21,1.91-.96,2.55-.74.64-1.59.96-2.55.96h-2.39c-.85,0-1.54-.18-2.07-.56-.53-.37-1.01-.88-1.44-1.51-1.06-1.7-2.34-3.29-3.83-4.78-1.49-1.49-3.48-2.68-5.98-3.59-2.5-.9-5.87-1.36-10.12-1.36-6.38,0-11.11,1.3-14.19,3.91-3.08,2.61-4.62,5.93-4.62,9.97,0,2.34.64,4.38,1.91,6.14s3.67,3.35,7.17,4.78c3.51,1.44,8.61,2.95,15.31,4.54,6.8,1.49,12.17,3.32,16.1,5.5,3.93,2.18,6.72,4.76,8.37,7.73,1.65,2.98,2.47,6.48,2.47,10.52,0,4.25-1.2,8.19-3.59,11.8-2.39,3.61-5.95,6.51-10.68,8.69-4.73,2.18-10.55,3.27-17.46,3.27Z"
        />
        <path
          className="cls-1"
          d="m233.48,219.85c-1.06,0-1.91-.32-2.55-.96s-.96-1.49-.96-2.55v-75.89c0-1.06.32-1.91.96-2.55.64-.64,1.49-.96,2.55-.96h2.71c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v5.58c2.66-3.4,5.82-6.03,9.49-7.89,3.67-1.86,8.1-2.79,13.31-2.79,11.9,0,20.41,5,25.51,14.99,2.44-4.57,6.03-8.21,10.76-10.92,4.73-2.71,10.18-4.07,16.34-4.07,5.63,0,10.71,1.3,15.23,3.91,4.52,2.61,8.08,6.46,10.68,11.56,2.6,5.1,3.91,11.48,3.91,19.13v46.4c0,1.06-.32,1.91-.96,2.55s-1.49.96-2.55.96h-2.71c-1.06,0-1.91-.32-2.55-.96-.64-.64-.96-1.49-.96-2.55v-44.8c0-7.02-1.01-12.46-3.03-16.34-2.02-3.88-4.68-6.62-7.97-8.21-3.3-1.59-6.85-2.39-10.68-2.39-3.19,0-6.43.8-9.72,2.39-3.3,1.59-6.03,4.33-8.21,8.21-2.18,3.88-3.27,9.33-3.27,16.34v44.8c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-2.71c-1.06,0-1.91-.32-2.55-.96s-.96-1.49-.96-2.55v-44.8c0-7.02-1.06-12.46-3.19-16.34-2.13-3.88-4.84-6.62-8.13-8.21-3.3-1.59-6.75-2.39-10.36-2.39-3.19,0-6.43.8-9.72,2.39-3.3,1.59-6.03,4.33-8.21,8.21-2.18,3.88-3.27,9.27-3.27,16.18v44.96c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-2.71Z"
        />
      </g>
      <path
        className="cls-1"
        d="m149.46,136.95v-26.79c0-1.06.32-1.91.96-2.55.64-.64,1.49-.96,2.55-.96h2.71c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v26.79h19.77c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v2.23c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-19.77v46.4c0,5.63.93,10.04,2.79,13.23,1.86,3.19,5.18,4.78,9.96,4.78h8.61c1.06,0,1.91.32,2.55.96s.96,1.49.96,2.55v2.23c0,1.06-.32,1.91-.96,2.55-.64.64-1.49.96-2.55.96h-9.41c-5.53,0-9.89-1.17-13.07-3.51-3.19-2.34-5.42-5.53-6.7-9.57-1.28-4.04-1.91-8.56-1.91-13.55v-47.03"
      />
      <path
        className="cls-1"
        d="m379.63,246.79v132.59H9.25v-132.59H0v138.33c0,1.06.32,1.91.96,2.55.64.64,1.49.96,2.55.96h381.86c1.06,0,1.91-.32,2.55-.96.64-.64.96-1.49.96-2.55v-138.33h-9.25Z"
      />
      <path
        className="cls-1"
        d="m379.63,135.35V9.25H9.25v132.59H0V3.51C0,2.44.32,1.59.96.96c.64-.64,1.49-.96,2.55-.96h381.86c1.06,0,1.91.32,2.55.96.64.64.96,1.49.96,2.55v131.84h-9.25Z"
      />
    </svg>
  )
}
