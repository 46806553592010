import React from 'react'
import { GlobalStyles as BaseStyles } from 'twin.macro'
import GlobalStyles from './GlobalStyles'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '@fontsource/titillium-web/300.css'
import '@fontsource/titillium-web/700.css'

import Seo from '../components/Header/Seo'

import CookieBar from './CookieBar'

export default function Layout({
  pageTitle,
  pageDesc,
  ogImage,
  darkMenu,
  children,
}) {
  return (
    <>
      <BaseStyles />
      <GlobalStyles />
      <Seo title={pageTitle} description={pageDesc} ogImage={ogImage} />
      <Header darkMenu={darkMenu} />
      <CookieBar />
      <main>{children}</main>
      <Footer />
    </>
  )
}
