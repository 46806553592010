import React, { useEffect, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useMediaQuery } from 'react-responsive'

import Nav from './Nav'
import NavItem from './NavItem'
import SubMenu from './SubMenu'

import Logo from '../Svgs/Logo'
import { graphql, useStaticQuery, Link } from 'gatsby'
import MobileMenuSvg from '../Svgs/MobileMenu'

import Search from './search'

const SubMenuWrapper = styled.li`
  &.mobile {
    ${tw`left-0 top-0 hidden py-8 px-8 fixed w-screen h-screen bg-white`}
  }
  &.mobile.show {
    ${tw`flex flex-col`}
  }
  &.desktop {
    ${tw`left-0 right-0 top-16 grid invisible absolute`}
    transition: all 0.5s;
    transform: perspective(600px) rotateX(-10deg);
  }
  &.desktop:hover {
    ${tw`visible`}
    transform: perspective(600px) rotateX(0);
  }
`

export default function Header({ darkMenu }) {
  const [mobileMenuState, setMobileMenuState] = useState(false)
  const [mobileSubMenuWrapperState, setMobileSubMenuWrapperState] =
    useState(false)
  const [mobileSubMenu, setMobileSubMenu] = useState('')

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 768px)' })

  useEffect(() => {
    isDesktopOrLaptop && setMobileMenuState(false)
  }, [isDesktopOrLaptop])

  const menuItems = useStaticQuery(graphql`
    query {
      allCraftPagesStandardEntry(sort: { fields: lft, order: ASC }) {
        nodes {
          title
          uri
          slug
          level
          hide
          noLink
          showAsButton
          remoteChildren {
            title
            uri
            slug
            ... on Craft_pages_standard_Entry {
              hide
              largeMenu
              menuDescription
              menuCategoryTitle
              menuIcon {
                title
                ... on Craft_productImages_Asset {
                  url
                  localFile {
                    extension

                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on Craft_pages_nyheder_Entry {
              largeMenu
              menuDescription
              menuCategoryTitle
              menuIcon {
                title
                ... on Craft_productImages_Asset {
                  url
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on Craft_pages_viden_Entry {
              largeMenu
              menuDescription
              menuCategoryTitle
              menuIcon {
                title
                ... on Craft_productImages_Asset {
                  url
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on Craft_pages_jobs_Entry {
              largeMenu
              menuDescription
              menuCategoryTitle
              menuIcon {
                title
                ... on Craft_productImages_Asset {
                  url
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
      allCraftProdukterKategoriEntry(
        sort: { fields: lft, order: ASC }
        filter: { enabled: { eq: true } }
      ) {
        nodes {
          title
          uri
          slug
          menuDescription
          menuCategoryTitle
          menuIcon {
            title
            ... on Craft_productImages_Asset {
              url
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <header
      css={[
        tw`text-white mt-5 md:mt-10 flex justify-between items-center absolute w-full top-0`,
        css`
          z-index: 9999;
        `,
      ]}
      className={`mm-px-dynamic ${darkMenu ? 'darkMenu' : ''}
        `}
    >
      <Link to="/" aria-label="STM Vinduer A/S gå til forsiden">
        <Logo variant={darkMenu && 'dark'} />
      </Link>
      <Nav
        darkMenu={darkMenu}
        mobileMenuState={mobileMenuState}
        setMobileMenuState={setMobileMenuState}
        setMobileSubMenuWrapperShow={setMobileSubMenuWrapperState}
      >
        {menuItems.allCraftPagesStandardEntry.nodes.map(
          (item, i) =>
            !item.hide &&
            item.level === 1 && (
              <NavItem
                key={i}
                slug={item.slug}
                darkMenu={darkMenu}
                nolink={item.noLink}
                href={'/' + item.uri}
                subMenuItems={item.remoteChildren}
                showAsButton={item.showAsButton}
                mobileMenuState={mobileMenuState}
                setMobileSubMenu={setMobileSubMenu}
                setMobileSubMenuWrapperShow={setMobileSubMenuWrapperState}
              >
                {item.title}
              </NavItem>
            )
        )}
        <SubMenuWrapper
          className={`subMenuWrapper ${mobileSubMenu} ${
            mobileSubMenuWrapperState && 'show'
          } ${mobileMenuState ? 'mobile' : 'desktop'}`}
        >
          {mobileMenuState && (
            <button
              aria-label="Vis hovedmenu"
              onClick={() => setMobileSubMenuWrapperState(false)}
            >
              <MobileMenuSvg variant="back" />
            </button>
          )}
          {menuItems.allCraftPagesStandardEntry.nodes.map(
            (item, i) =>
              !item.hide &&
              item.level === 1 && (
                <SubMenu
                  key={i}
                  mobileSubMenu={mobileSubMenu}
                  slug={item.slug}
                  mobileMenuState={mobileMenuState}
                  subMenuItems={item.remoteChildren}
                  productCategories={
                    item.slug === 'produkter'
                      ? menuItems.allCraftProdukterKategoriEntry.nodes
                      : null
                  }
                />
              )
          )}
        </SubMenuWrapper>
        <Search />
      </Nav>
    </header>
  )
}
