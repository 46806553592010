import React from 'react'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import tw, { css } from 'twin.macro'
import { useCookies } from 'react-cookie'
import { getCookie } from 'gatsby-plugin-gdpr-cookies/helper'

export default function CookieBar() {
  /* eslint-disable no-unused-vars */
  const [cookies, setCookie] = useCookies(['stm-gdpr-google-analytics'])
  const location = useLocation()

  let show

  if (typeof window !== 'undefined') {
    show = !getCookie('stm-gdpr-google-analytics')
  }

  function handleCookieChoice(val) {
    setCookie('stm-gdpr-google-analytics', val, {
      path: '/',
      sameSite: 'strict',
      maxAge: val ? 2592000 : 86400,
    })
    initializeAndTrack(location)
  }

  if (!show) return <></>

  return (
    <div
      css={[
        tw`fixed bottom-5 left-5 right-5 m-auto md:m-0 xl:max-w-screen-lg lg:max-w-screen-md text-sm bg-navy text-white md:flex py-4 px-4 sm:px-0 sm:pl-10 sm:py-6 rounded-lg md:space-x-8 shadow-2xl transform transition-transform hover:scale-105`,
        css`
          z-index: 9999;
        `,
      ]}
    >
      <p css={tw`m-0 pb-4 md:pb-0`}>
        Vores website anvender cookies. Du kan læse mere om hvilke cookies, som
        siden bruger i nedenstående. Her kan du også styre hvilke cookies, som
        du ønsker at tilvælge. Se også vores{' '}
        <a href="/policy" css={tw`text-white underline`}>
          {' '}
          cookiepolitik her
        </a>
        .
      </p>
      <div css={tw`flex flex-row items-end`}>
        <button
          className="button buttonNeg"
          css={tw`px-6 md:px-10 `}
          onClick={() => handleCookieChoice(true)}
        >
          OK
        </button>
        <button
          css={tw`border border-white py-2 font-bold w-52 text-white rounded-full opacity-60 hover:opacity-40 ml-1 md:ml-4 mr-10`}
          onClick={() => handleCookieChoice(false)}
        >
          Kun nødvendige cookies
        </button>
      </div>
    </div>
  )
}
