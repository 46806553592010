import React from 'react'
import { Global, css } from '@emotion/react'
import tw from 'twin.macro'

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        @media (max-width: 768px) {
          // h1 > br,
          // h2 > br,
          // h3 > br {
          //   content: ' ';
          // }
          // h1 > br::after,
          // h2 > br::after,
          // h3 > br::after {
          //   content: ' ';
          // }
        }
        body {
          overflow-x: hidden;
        }
        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        section.readMoreHide:last-of-type {
          margin-bottom: 4rem;
        }
        section.readMoreHide + section.readMoreHide {
          display: none;
        }
        section.readMoreHide {
          height: 200px;
          overflow: hidden;
        }
        section.readMoreHide::after {
          content: '';
          position: absolute;
          width: 100%;
          display: inline-block;
          height: 100%;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 20%,
            white 80%
          );
        }
        section.readMoreHide button.readMore,
        .button,
        .buttonP a {
          background: #464340;
          color: white;
          text-align: center;
          padding: 10px 50px 10px 50px;
          border-radius: 50px;
          font-weight: 700;
          cursor: pointer;
          display: inline-block;
        }
        .button.buttonNeg {
          ${tw`text-stm bg-white`}
        }
        section.readMoreHide button.readMore {
          position: absolute;
          left: 50%;
          margin-left: -80px;
          bottom: 0;
          z-index: 10;
        }
        section.readMoreHide button.readMore:hover,
        .button:hover,
        .buttonP a:hover {
          opacity: 0.9;
        }
        a {
          ${tw`text-stmlight`}
        }
        a.active {
          ${tw` font-bold flex flex-col`}
          color: #999 !important;
        }
        .mobile a.active span {
          ${tw`text-stm`}
        }
        .mainActive {
          ${tw`border-b-4 border-white`}
        }
        .darkMenu .mainActive {
          ${tw`border-navy`}
        }

        .darkMenu .searchIcon {
          fill: #222 !important;
        }

        .darkMenu .searchIcon circle,
        .darkMenu .searchIcon line,
        .show .searchIcon circle,
        .show .searchIcon line {
          stroke: #222 !important;
        }

        .show .closeSearch {
          ${tw`absolute top-0 right-0 mt-12 mr-16 z-50`}
        }
        .searchOpen .toggleNav > button {
          display: none;
        }

        main li {
          ${tw`list-disc list-inside`}
        }
        .textImageSquares li,
        .oneCol li,
        .twoCols li,
        .threeCols li {
          ${tw`list-disc list-outside ml-6`}
        }
        .textImageSquares ol li,
        .oneCol ol li,
        .twoCols ol li,
        .threeCols ol li {
          ${tw`list-decimal `}
        }
        footer a {
          ${tw`text-navy`}
        }
        footer h5 {
          ${tw`text-lg mb-4`}
        }
        // footer ul {
        //   ${tw`list-disc list-outside ml-4`}
        // }
        .link,
        .linkP a {
          ${tw`font-bold text-navy`}
        }
        .link::before,
        .linkP a::before {
          ${tw`font-bold text-white bg-navy inline-block rounded-full text-center text-2xl leading-none mr-3 mt-4`}
          content:'›';
          height: 30px;
          width: 30px;
        }

        .divide {
          ${tw`border-t-2 border-b-2 border-sand`}
        }
        section.textImage + section.textImage > .divide,
        section.textImage + section.textImage > a > .divide {
          ${tw`border-t-0 border-b-2 border-sand`}
        }
        .container {
          width: 100%;
          margin-right: auto;
          margin-left: auto;
        }
        .mm-h-half {
          min-height: 200px;
          height: 40vh;
        }
        .mm-px-dynamic {
          padding-left: 5vw;
          padding-right: 5vw;
        }
        .mm-py-dynamic {
          padding-top: 7vw;
          padding-bottom: 7vw;
        }
        .mm-py-dynamic-content {
          padding-top: 7vw;
          padding-bottom: 3vw;
        }
        .mm-py-dynamic-content-sm {
          padding-top: 3vw;
          padding-bottom: 1vw;
        }
        .mm-py-dynamic-sm {
          padding-top: 3vw;
          padding-bottom: 3vw;
        }
        .mm-mx-dynamic {
          margin-left: 5vw;
          margin-right: 5vw;
        }
        .mm-my-dynamic {
          margin-top: 7vw;
          margin-bottom: 7vw;
        }
        .mm-my-dynamic-sm {
          margin-top: 3vw;
          margin-bottom: 3vw;
        }
        .mm-py-dynamic-sm {
          padding-top: 3vw;
          padding-bottom: 3vw;
        }

        main section:first-of-type.divider {
          ${tw`pt-16`}
        }
        main section:first-of-type.oneCol {
          ${tw`pt-24`}
        }

        body {
          ${tw`bg-white text-base font-sans text-navy`}
        }
        p {
          ${tw`mb-5`}
        }
        .textImage p:last-of-type {
          ${tw`mb-0 text-base`}
        }

        h1:last-child,
        h2:last-child,
        h3:last-child,
        h4:last-child,
        h5:last-child {
          ${tw`mb-0`} !important;
        }

        .heroSubHeading p {
          ${tw`mb-0`}
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        .heroSubHeading p {
          ${tw`font-bold leading-none`}
        }
        h1 {
          font-size: 3rem;
          line-height: 3rem;
        }

        h2,
        .textImageSquares h3 {
          font-size: 2rem;
        }
        h2 + p,
        h2 + ul,
        h2 + ol,
        h2 + h5 {
          ${tw`mt-6`}
        }
        h3 {
          ${tw`text-2xl`}
        }
        h3 + p,
        h3 + ol,
        h3 + ul {
          ${tw`mt-6`}
        }
        h4,
        .heroSubHeading p {
          ${tw`text-xl leading-tight`}
        }
        h4 + p,
        h4 + ol,
        h4 + ul {
          ${tw`mt-6`}
        }
        h5 {
          ${tw`text-2xl leading-tight`}
        }
        h5 + p,
        h5 + ol,
        h5 + ul {
          ${tw`mt-5`}
        }

        @media (min-width: 768px) {
          section.readMoreHide {
            height: 400px;
          }

          h2 + p,
          h2 + ul,
          h2 + ol,
          h2 + h5 {
            ${tw`mt-16`}
          }

          .hero h1,
          .TextImage h1 {
            ${tw`text-7xl`}
            line-height: 5rem;
          }

          h1,
          h2,
          .textImageSquares h3 {
            font-size: 3rem;
          }

          .textImage h3 {
            font-size: 3rem;
            ${tw`mb-0`}
          }
          .textImage h2 {
            font-size: 4rem;
            ${tw`mb-0`}
          }

          .mm-h-half {
            min-height: 600px;
            height: 50vh;
          }
        }

        @media (min-width: 1280px) {
          body {
            font-size: 1.2rem !important;
          }
          .hero h1,
          .TextImage h1 {
            ${tw`text-10xl`}
            line-height: 6vw;
          }

          h4,
          .heroSubHeading p {
            ${tw`text-2xl`}
          }
          h1,
          h2,
          .textImageSquares h3,
          .oneCol h2,
          .twoCols h2,
          .threeCols h2 {
            ${tw`mb-16`}
            font-size: 3vw;
          }
          h3,
          .oneCol h3,
          .twoCols h3,
          .threeCols h3 {
            font-size: 2rem;
          }
          .textImage h3 {
            font-size: 3vw;
          }
          .textImage h2 {
            font-size: 5vw;
          }
        }
        @media (min-width: 1900px) {
          .hero h1,
          .TextImage h1 {
            font-size: 9rem;
            line-height: 0.8;
          }
          .h1,
          .textImageSquares h3,
          .oneCol h2,
          .twoCols h2,
          .threeCols h2 {
            ${tw`mb-16`}
            font-size: 3.5rem;
          }
          .oneCol h3,
          .twoCols h3,
          .threeCols h3 {
            font-size: 2.5rem;
          }

          .textImage h3 {
            font-size: 4rem;
          }
          .textImage h2 {
            font-size: 6rem;
          }
        }

        @media (max-height: 900px) and (orientation: landscape) {
          .hero h1 {
            ${tw`text-7xl`}
            line-height: 5rem;
          }

          h2,
          .textImageSquares h3 {
            font-size: 3rem;
          }
        }
      `}
    />
  )
}
