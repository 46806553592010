import React, { useState } from 'react'
import tw from 'twin.macro'
import { Helmet } from 'react-helmet'

function Search() {
  const [openConfirm, setOpenConfirm] = useState(false)
  return (
    <React.Fragment>
      <>
        <li css={tw`mt-6`}>
          {!openConfirm && (
            <button
              aria-label="Search open"
              onClick={() => setOpenConfirm(true)}
            >
              <svg
                className="searchIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="#fff"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="116"
                  cy="116"
                  r="84"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="22"
                ></circle>
                <line
                  x1="175.39356"
                  y1="175.40039"
                  x2="223.99414"
                  y2="224.00098"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="22"
                ></line>
              </svg>
            </button>
          )}
          {openConfirm && (
            <>
              <Helmet>
                <body className="searchOpen" />
              </Helmet>
              <button
                aria-label="Search close"
                onClick={() => setOpenConfirm(false)}
                className="closeSearch"
              >
                <svg
                  className="searchIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 36.761 36.761"
                >
                  <line
                    id="Line_20"
                    data-name="Line 20"
                    x2="48.987"
                    transform="translate(1.061 1.061) rotate(45)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                  />
                  <line
                    id="Line_21"
                    data-name="Line 21"
                    x2="48.987"
                    transform="translate(1.061 35.7) rotate(-45)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                  />
                </svg>
              </button>
            </>
          )}
        </li>

        {openConfirm && (
          <section
            css={tw`bg-white fixed left-0 top-0 bottom-0 shadow-2xl w-full md:max-w-md max-w-3xl`}
          >
            <iframe
              title="Search"
              css={tw`h-full w-full md:max-w-md max-w-3xl`}
              src="https://content.stmvinduer.dk/search"
              frameborder="0"
              scrolling="yes"
            />
          </section>
        )}
      </>
    </React.Fragment>
  )
}

export default Search
